import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import { FullUserFragmentDoc } from '../fragments/user.fragment.generated';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GenerateMfatotpQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GenerateMfatotpQuery = { __typename?: 'Query', generateMFATOTP: string };

export type VerifyTotpMutationVariables = Types.Exact<{
  input: Types.TotpInput;
}>;


export type VerifyTotpMutation = { __typename?: 'Mutation', verifyTOTP: { __typename?: 'MFABackupCodes', codes: Array<string> } };

export type MfaLoginMutationVariables = Types.Exact<{
  input: Types.MfaLoginInput;
}>;


export type MfaLoginMutation = { __typename?: 'Mutation', mfaLogin: { __typename?: 'LoginResponse', token: string | null, expire: string | null, user: { __typename?: 'User', id: string, email: string | null, address: string | null, title: string | null, firstName: string | null, lastName: string | null, organizationId: string | null, phoneNumber: string | null, profileImage: string | null, role: Types.UserRoles | null, lastLoggedIn: any | null, onboardingStep: string | null, onboardingCompleted: any | null, mfaEnabled: boolean | null, organization: { __typename?: 'Organization', id: string, name: string | null, logo: string | null, logoHorizontal: string | null, maxUsers: number | null, organizationTypes: Array<string | null> | null, invitationsRemaining: number | null, hardware: Array<string | null> | null, createdAt: any | null, modifiedAt: any | null, createdById: string | null, resourceCostUnit: Types.ResourceCostUnit | null, tld: string | null } | null, acknowledgements: { __typename?: 'Acknowledgements', termsOfUse: boolean } | null, filters: Array<{ __typename?: 'Filter', id: string, name: string } | null> | null } | null, userPreferences: { __typename?: 'UserPreferences', id: string | null, userId: string | null, preferences: any | null } | null } };

export type RemoveMfaMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type RemoveMfaMutation = { __typename?: 'Mutation', removeMFA: { __typename?: 'User', id: string, email: string | null, address: string | null, title: string | null, firstName: string | null, lastName: string | null, organizationId: string | null, phoneNumber: string | null, profileImage: string | null, role: Types.UserRoles | null, lastLoggedIn: any | null, onboardingStep: string | null, onboardingCompleted: any | null, mfaEnabled: boolean | null, organization: { __typename?: 'Organization', id: string, name: string | null, logo: string | null, logoHorizontal: string | null, maxUsers: number | null, organizationTypes: Array<string | null> | null, invitationsRemaining: number | null, hardware: Array<string | null> | null, createdAt: any | null, modifiedAt: any | null, createdById: string | null, resourceCostUnit: Types.ResourceCostUnit | null, tld: string | null } | null, acknowledgements: { __typename?: 'Acknowledgements', termsOfUse: boolean } | null, filters: Array<{ __typename?: 'Filter', id: string, name: string } | null> | null } };

export type RegenerateMfaBackupCodesMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type RegenerateMfaBackupCodesMutation = { __typename?: 'Mutation', generateMFABackupCodes: { __typename?: 'MFABackupCodes', codes: Array<string> } };


export const GenerateMfatotpDocument = gql`
    query GenerateMFATOTP {
  generateMFATOTP
}
    `;

/**
 * __useGenerateMfatotpQuery__
 *
 * To run a query within a React component, call `useGenerateMfatotpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateMfatotpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateMfatotpQuery({
 *   variables: {
 *   },
 * });
 */
export function useGenerateMfatotpQuery(baseOptions?: Apollo.QueryHookOptions<GenerateMfatotpQuery, GenerateMfatotpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateMfatotpQuery, GenerateMfatotpQueryVariables>(GenerateMfatotpDocument, options);
      }
export function useGenerateMfatotpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateMfatotpQuery, GenerateMfatotpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateMfatotpQuery, GenerateMfatotpQueryVariables>(GenerateMfatotpDocument, options);
        }
export function useGenerateMfatotpSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GenerateMfatotpQuery, GenerateMfatotpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GenerateMfatotpQuery, GenerateMfatotpQueryVariables>(GenerateMfatotpDocument, options);
        }
export type GenerateMfatotpQueryHookResult = ReturnType<typeof useGenerateMfatotpQuery>;
export type GenerateMfatotpLazyQueryHookResult = ReturnType<typeof useGenerateMfatotpLazyQuery>;
export type GenerateMfatotpSuspenseQueryHookResult = ReturnType<typeof useGenerateMfatotpSuspenseQuery>;
export type GenerateMfatotpQueryResult = Apollo.QueryResult<GenerateMfatotpQuery, GenerateMfatotpQueryVariables>;
export const VerifyTotpDocument = gql`
    mutation VerifyTOTP($input: TOTPInput!) {
  verifyTOTP(input: $input) {
    codes
  }
}
    `;
export type VerifyTotpMutationFn = Apollo.MutationFunction<VerifyTotpMutation, VerifyTotpMutationVariables>;

/**
 * __useVerifyTotpMutation__
 *
 * To run a mutation, you first call `useVerifyTotpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTotpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTotpMutation, { data, loading, error }] = useVerifyTotpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyTotpMutation(baseOptions?: Apollo.MutationHookOptions<VerifyTotpMutation, VerifyTotpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyTotpMutation, VerifyTotpMutationVariables>(VerifyTotpDocument, options);
      }
export type VerifyTotpMutationHookResult = ReturnType<typeof useVerifyTotpMutation>;
export type VerifyTotpMutationResult = Apollo.MutationResult<VerifyTotpMutation>;
export type VerifyTotpMutationOptions = Apollo.BaseMutationOptions<VerifyTotpMutation, VerifyTotpMutationVariables>;
export const MfaLoginDocument = gql`
    mutation MFALogin($input: MFALoginInput!) {
  mfaLogin(input: $input) {
    user {
      ...FullUser
    }
    userPreferences {
      id
      userId
      preferences
    }
    token
    expire
  }
}
    ${FullUserFragmentDoc}`;
export type MfaLoginMutationFn = Apollo.MutationFunction<MfaLoginMutation, MfaLoginMutationVariables>;

/**
 * __useMfaLoginMutation__
 *
 * To run a mutation, you first call `useMfaLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMfaLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mfaLoginMutation, { data, loading, error }] = useMfaLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMfaLoginMutation(baseOptions?: Apollo.MutationHookOptions<MfaLoginMutation, MfaLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MfaLoginMutation, MfaLoginMutationVariables>(MfaLoginDocument, options);
      }
export type MfaLoginMutationHookResult = ReturnType<typeof useMfaLoginMutation>;
export type MfaLoginMutationResult = Apollo.MutationResult<MfaLoginMutation>;
export type MfaLoginMutationOptions = Apollo.BaseMutationOptions<MfaLoginMutation, MfaLoginMutationVariables>;
export const RemoveMfaDocument = gql`
    mutation RemoveMFA {
  removeMFA {
    ...FullUser
  }
}
    ${FullUserFragmentDoc}`;
export type RemoveMfaMutationFn = Apollo.MutationFunction<RemoveMfaMutation, RemoveMfaMutationVariables>;

/**
 * __useRemoveMfaMutation__
 *
 * To run a mutation, you first call `useRemoveMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMfaMutation, { data, loading, error }] = useRemoveMfaMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveMfaMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMfaMutation, RemoveMfaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMfaMutation, RemoveMfaMutationVariables>(RemoveMfaDocument, options);
      }
export type RemoveMfaMutationHookResult = ReturnType<typeof useRemoveMfaMutation>;
export type RemoveMfaMutationResult = Apollo.MutationResult<RemoveMfaMutation>;
export type RemoveMfaMutationOptions = Apollo.BaseMutationOptions<RemoveMfaMutation, RemoveMfaMutationVariables>;
export const RegenerateMfaBackupCodesDocument = gql`
    mutation RegenerateMFABackupCodes {
  generateMFABackupCodes {
    codes
  }
}
    `;
export type RegenerateMfaBackupCodesMutationFn = Apollo.MutationFunction<RegenerateMfaBackupCodesMutation, RegenerateMfaBackupCodesMutationVariables>;

/**
 * __useRegenerateMfaBackupCodesMutation__
 *
 * To run a mutation, you first call `useRegenerateMfaBackupCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateMfaBackupCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateMfaBackupCodesMutation, { data, loading, error }] = useRegenerateMfaBackupCodesMutation({
 *   variables: {
 *   },
 * });
 */
export function useRegenerateMfaBackupCodesMutation(baseOptions?: Apollo.MutationHookOptions<RegenerateMfaBackupCodesMutation, RegenerateMfaBackupCodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegenerateMfaBackupCodesMutation, RegenerateMfaBackupCodesMutationVariables>(RegenerateMfaBackupCodesDocument, options);
      }
export type RegenerateMfaBackupCodesMutationHookResult = ReturnType<typeof useRegenerateMfaBackupCodesMutation>;
export type RegenerateMfaBackupCodesMutationResult = Apollo.MutationResult<RegenerateMfaBackupCodesMutation>;
export type RegenerateMfaBackupCodesMutationOptions = Apollo.BaseMutationOptions<RegenerateMfaBackupCodesMutation, RegenerateMfaBackupCodesMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGenerateMfatotpQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ generateMFATOTP })
 *   )
 * })
 */
export const mockGenerateMfatotpQuery = (resolver: Parameters<typeof graphql.query<GenerateMfatotpQuery, GenerateMfatotpQueryVariables>>[1]) =>
  graphql.query<GenerateMfatotpQuery, GenerateMfatotpQueryVariables>(
    'GenerateMFATOTP',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockVerifyTotpMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ verifyTOTP })
 *   )
 * })
 */
export const mockVerifyTotpMutation = (resolver: Parameters<typeof graphql.mutation<VerifyTotpMutation, VerifyTotpMutationVariables>>[1]) =>
  graphql.mutation<VerifyTotpMutation, VerifyTotpMutationVariables>(
    'VerifyTOTP',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockMfaLoginMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ mfaLogin })
 *   )
 * })
 */
export const mockMfaLoginMutation = (resolver: Parameters<typeof graphql.mutation<MfaLoginMutation, MfaLoginMutationVariables>>[1]) =>
  graphql.mutation<MfaLoginMutation, MfaLoginMutationVariables>(
    'MFALogin',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRemoveMfaMutation((req, res, ctx) => {
 *   return res(
 *     ctx.data({ removeMFA })
 *   )
 * })
 */
export const mockRemoveMfaMutation = (resolver: Parameters<typeof graphql.mutation<RemoveMfaMutation, RemoveMfaMutationVariables>>[1]) =>
  graphql.mutation<RemoveMfaMutation, RemoveMfaMutationVariables>(
    'RemoveMFA',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRegenerateMfaBackupCodesMutation((req, res, ctx) => {
 *   return res(
 *     ctx.data({ generateMFABackupCodes })
 *   )
 * })
 */
export const mockRegenerateMfaBackupCodesMutation = (resolver: Parameters<typeof graphql.mutation<RegenerateMfaBackupCodesMutation, RegenerateMfaBackupCodesMutationVariables>>[1]) =>
  graphql.mutation<RegenerateMfaBackupCodesMutation, RegenerateMfaBackupCodesMutationVariables>(
    'RegenerateMFABackupCodes',
    resolver
  )
