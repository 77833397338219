import { Button } from '@/components';
import TextInput from '@/components/inputs/TextInput/TextInput';
import VerifyMFAModal from '@/components/modals/VerifyMFAModal/VerifyMFAModal';
import { addToastError } from '@/components/Toast/utils';
import { useLoginMutation } from '@/graphql/auth/login.generated';
import { useModal } from '@/hooks/useModal';
import { type SSOQueryParams, useQueryParams } from '@/hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { selectRootState } from '@/state/selectors';
import { setUser } from '@/state/slices/session.slice';
import { API_URL } from '@/util/constants';
import { env } from '@/util/env.utils';
import { loginWithKeycloak, postForgotPasswordStart } from '@/util/requests.functions';
import { useApolloClient } from '@apollo/client';
import { hasCookie } from 'cookies-next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { type NextPage } from 'next';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import { type FormEvent, useCallback, useEffect, useMemo, useState } from 'react';

const LoginPage: NextPage = () => {
  const state = useAppSelector(selectRootState);
  const {
    navigation: { lastVisited },
  } = state;
  const client = useApolloClient();
  const router = useRouter();
  const { queryParams, updateUrlParams } = useQueryParams<SSOQueryParams>();
  const dispatch = useAppDispatch();
  const [callLogin] = useLoginMutation();
  const { googleSso, microsoftSso } = useFlags();
  const { showModal, closeModal } = useModal();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [shouldShowMessage, setShouldShowMessage] = useState<boolean>(false);

  const isKeycloakRestricted = useMemo(() => {
    return ['il2', 'il4', 'il5', 'il6'].includes(env('NEXT_PUBLIC_STAGE'));
  }, [env('NEXT_PUBLIC_STAGE')]);

  useEffect(() => {
    const { mfa, token, code, email: queryEmail, ...rest } = queryParams;

    if (mfa?.toLowerCase() === 'true' && token) {
      showModal(
        <VerifyMFAModal userEmail={queryEmail} sessionToken={token} closeModal={closeModal} />,
        {
          showCloseIcon: false,
          isMegaModal: true,
        },
      );
    }

    updateUrlParams({ ...rest });
  }, []);

  const login = useCallback(
    async (event: FormEvent<HTMLFormElement>, email: string, password: string): Promise<void> => {
      event.preventDefault();
      try {
        const response = await callLogin({ variables: { input: { email, password } } });
        if (response?.errors?.length) {
          if (response?.errors[0]?.message?.includes('user is deactivated due to plan change')) {
            addToastError('User is deactivated due to plan change. Please contact support.');
          } else {
            addToastError('Username or Password is incorrect.');
          }
          return;
        } else if (response?.data?.login?.needsMFA) {
          showModal(
            <VerifyMFAModal userEmail={email} sessionToken={response.data.login.token} closeModal={closeModal} />,
            {
              showCloseIcon: false,
              isMegaModal: true,
            },
          );
        } else {
          dispatch(setUser(response?.data?.login?.user));
          setIsLoggedIn(true);
        }
      } catch (err) {
        console.error(err);
        if (err?.message?.includes('user is deactivated due to plan change')) {
          addToastError('User is deactivated due to plan change. Please contact support.');
        } else {
          addToastError('Username or Password is incorrect.');
        }
      }
    },
    [callLogin, dispatch],
  );

  const _loginWithKeycloak = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    try {
      const response = await loginWithKeycloak();
      dispatch(setUser(response.user));
      setIsLoggedIn(true);
    } catch (err) {
      console.error(err);
      if (err?.message?.includes('user is deactivated due to plan change')) {
        addToastError('User is deactivated due to plan change. Please contact support.');
      } else {
        addToastError('Username or Password is incorrect.');
      }
    }
  };

  // If there exists a jwt cookie, user is logged in
  useEffect(() => {
    void (async () => {
      if (hasCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'))) {
        await router.push(lastVisited || '/');
      } else if (isLoggedIn) {
        setIsLoggedIn(false);
      }
    })();
  }, [isLoggedIn, lastVisited]);

  const trimmedEmail = email.trim();
  const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(trimmedEmail);

  const handleForgotPassword = async (): Promise<void> => {
    try {
      setShouldShowMessage(true);
      await postForgotPasswordStart({ email: trimmedEmail });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // Clear the apollo store on login page load
    void client.clearStore();
  }, []);

  const message = !isValidEmail
    ? (
      <div className='flex w-full'>
        <div className='px-1.5'>
          <Image src='/images/caution-triangle.svg' width={18} height={18} alt='Caution Triangle' />
        </div>
        Enter a valid email address above to reset your password.
      </div>
    )
    : 'No problem! We just sent you an email. Check your inbox for instructions on how to reset your password. If you don\'t see it, check your spam folder and spelling or contact support@integrate.co.';

  return (
    <section className='fixed w-full h-screen bg-[#F1DFDB] item-center overflow-hidden'>
      <div className={`h-full w-full relative overflow-hidden`}>
        {/* Background Noise */}
        <Image
          className='z-20 pointer-events-none mix-blend-multiply'
          src='/images/login-bg-noise.png'
          layout='fill'
          objectFit='cover'
          alt='Background Noise'
        />

        {/* Content */}
        <div className='flex flex-col w-full h-full'>
          <div className='flex justify-center w-full h-full'>
            <div className='flex overflow-auto relative flex-col pt-[60px] p-[40px] grow 2xl:max-w-[1440px]'>
              {/* Star Divider */}
              <div className='relative h-[120px] shrink-0'>
                <Image
                  src='/images/star-divider.svg'
                  layout='fill'
                  objectFit='cover'
                  objectPosition='center'
                  alt='Star Divider'
                  height={120}
                  width={1360}
                />
              </div>

              {/* Nav */}
              <div className='h-0 translate-y-[-140px]'>
                <div className='flex justify-between items-center h-[36px]'>
                  <a className='relative w-[129px] h-[24px]' href='https://integrate.co'>
                    <Image
                      src='/images/integrate-logo-black.svg'
                      alt='Wordmark'
                      layout='fill'
                      objectFit='contain'
                      objectPosition='center left'
                    />
                  </a>

                  <Button
                    href='/signup'
                    className='flex justify-center items-center text-black border border-black saans-16 w-[182px] rounded-[18px] h-[36px]'
                  >
                    Create an account
                  </Button>
                </div>
              </div>

              {/* Login Form */}
              <div className='flex flex-col justify-center items-center w-full h-full grow'>
                <div className='relative w-[320px] bg-[#f1dfdb] bg-opacity-90 rounded-[32px] flex flex-col gap-[32px]'>
                  <div className='flex flex-col gap-8'>
                    <div className='text-center uppercase font-ppmonument text-[32px] xl:text-[40px]'>Login</div>
                    <p className='leading-5 text-center'>
                      Next-generation program management for the world&rsquo;s most complex industries.
                    </p>
                  </div>
                  <form
                    id='login-form'
                    onSubmit={(event) =>
                      !isKeycloakRestricted ? login(event, email, password) : _loginWithKeycloak(event)}
                    className='flex flex-col w-full gap-[32px]'
                  >
                    {!isKeycloakRestricted ?
                      (
                        <>
                          <TextInput
                            id='username'
                            type='text'
                            autoFocus={true}
                            value={email}
                            onChange={(event) => {
                              setEmail(event.target.value.trim());
                            }}
                            onFocus={() => {
                              setShouldShowMessage(false);
                            }}
                            placeholder='Email'
                            unstyledInput
                            className='grow saans-24 !shadow-none !p-0 placeholder:text-black bg-transparent border-b outline-none px-2 w-full ring-0 transition-all focus:border-primary effra-12 pt-2 pb-1 border-black text-black self-center'
                          />
                          <TextInput
                            id='password'
                            type='password'
                            showPasswordButton
                            value={password}
                            onChange={(event): void => {
                              setPassword(event.target.value);
                            }}
                            placeholder='Password'
                            unstyledInput
                            className='grow saans-24 !shadow-none !p-0 placeholder:text-black bg-transparent border-b outline-none px-2 w-full ring-0 transition-all focus:border-primary effra-12 pt-2 pb-1 border-black text-black self-center'
                          />
                          <Button
                            id='btn-login'
                            data-testid='app-login-button'
                            className='rounded-[32px] xl:h-[56px] h-[48px] flex items-center justify-center rounded-4xl text-base uppercase text-white bg-[#5D35E5]'
                            doesRipple
                            doesSpin
                          >
                            Log in
                          </Button>
                          <div className='mx-auto h-8 text-black'>
                            {!shouldShowMessage ?
                              (
                                <button className='underline text-[16px]' onClick={handleForgotPassword}>
                                  Forgot password
                                </button>
                              )
                              : <div className='mx-auto'>{message}</div>}
                          </div>
                          {(googleSso || microsoftSso) && (
                            <>
                              <div className='mx-auto'>
                                Or continue with:
                              </div>
                              <div className='flex flex-row gap-10 justify-center items-center -mt-5'>
                                {googleSso && (
                                  <Image
                                    src='/images/google-logo.png'
                                    alt='Google'
                                    width={24}
                                    height={24}
                                    className='cursor-pointer'
                                    onClick={() => {
                                      window.location.href = `${API_URL()}/rest/v1/auth/sso/google-oidc`;
                                    }}
                                  />
                                )}
                                {microsoftSso && (
                                  <Image
                                    src='/images/microsoft-logo.png'
                                    alt='Microsoft'
                                    width={24}
                                    height={24}
                                    className='cursor-pointer'
                                    onClick={() => {
                                      window.location.href = `${API_URL()}/rest/v1/auth/sso/microsoft-oidc`;
                                    }}
                                  />
                                )}
                              </div>
                            </>
                          )}
                        </>
                      ) :
                      (
                        <Button
                          id='btn-login'
                          data-testid='app-login-button'
                          className='rounded-[32px] mb-[32px] xl:h-[56px] h-[48px] mt-[25%] flex items-center justify-center rounded-4xl text-base uppercase text-white bg-[#0d1815]'
                          doesRipple
                          doesSpin
                        >
                          Log in with Keycloak
                        </Button>
                      )}
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* Footer Hills */}
          <div className='relative flex 2xl:h-[22%] xl:h-[19%] lg:h-[28%] h-[30%] w-[100%] shrink-0'>
            <Image
              className='block h-full pointer-events-none z-[-1]'
              src='/images/login-footer-hills.svg'
              layout='fill'
              objectFit='cover'
              objectPosition='center top'
              alt='Footer Hills'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
