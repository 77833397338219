import { Input } from '@/components/form/Input';
import { addToastSuccess } from '@/components/Toast/utils';
import { useMfaLoginMutation } from '@/graphql/auth/mfa.generated';
import { useAppDispatch } from '@/state/hooks';
import { setUser } from '@/state/slices/session.slice';
import { useRouter } from 'next/router';
import { useState } from 'react';

interface VerifyMFAModalProps {
  userEmail: string;
  sessionToken: string;
  closeModal: () => void;
}

export default function VerifyMFAModal ({ userEmail, sessionToken, closeModal }: VerifyMFAModalProps): JSX.Element {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [isUsingBackupCode, setIsUsingBackupCode] = useState<boolean>(false);
  const [mfaLogin] = useMfaLoginMutation();

  return (
    <div className='flex flex-col gap-4 p-10 w-[400px]'>
      <div>
        <label className='label' htmlFor='input-verify-mfa'>{isUsingBackupCode ? 'Backup' : 'MFA'} Code:</label>
        <Input
          value={verificationCode}
          className='grow input-text'
          type='text'
          id='input-verify-mfa'
          onChange={(e) => {
            setVerificationCode(e.target.value);
          }}
          placeholder={`Enter ${isUsingBackupCode ? 'Backup' : 'MFA'} code`}
        />
      </div>
      <button
        className='w-full btn-primary h-[36px]'
        onClick={async () => {
          const result = await mfaLogin({
            variables: {
              input: {
                sessionToken,
                userEmail,
                code: !isUsingBackupCode ? verificationCode : null,
                backupCode: isUsingBackupCode ? verificationCode : null,
              },
            },
          });
          if (result?.errors?.length) {
            addToastSuccess(`Incorrect ${isUsingBackupCode ? 'Backup' : 'MFA'} code.`);
          } else {
            dispatch(setUser(result?.data?.mfaLogin?.user));
            void router.push('/');
            closeModal();
          }
        }}
      >
        Login
      </button>
      <a onClick={() => setIsUsingBackupCode(!isUsingBackupCode)}>
        Use {isUsingBackupCode ? 'an MFA' : 'a Backup'} code?
      </a>
    </div>
  );
}
